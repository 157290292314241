var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalAsignarOperador",attrs:{"titulo":"Asignar entregas al operador logístico","tamano":"modal-xl","no-aceptar":"","adicional":"Asignar"},on:{"adicional":_vm.asignar}},[_c('div',{staticClass:"row mx-0 align-items-center py-3"},[_c('div',{staticClass:"col-auto text-general f-18 f-500"},[_vm._v(" Entregas programadas para el: "+_vm._s(_vm._f("helper-fecha")(_vm.fecha,'dddd DD MMMM YYYY'))+" "+_vm._s(_vm.hora)+" ")])]),_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-12"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.datos,"header-row-class-name":"text-general f-16 f-500"},on:{"selection-change":_vm.seleccionarPedido}},[_c('el-table-column',{attrs:{"fixed":"","type":"selection","width":"45","prop":"id","value":"id"}}),_c('el-table-column',{attrs:{"fixed":"","class-name":"text-center","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"row mx-0 justify-content-around"},[_c('div',{staticClass:"col px-0"},[(row.intentos)?_c('el-tooltip',{attrs:{"placement":"bottom","content":"Nuevo intento de entrega","effect":"light"}},[_c('i',{staticClass:"icon-history f-20 text-general mx-2"})]):_vm._e()],1),_c('div',{staticClass:"col px-0"},[(row.agotado)?_c('el-tooltip',{attrs:{"placement":"bottom","content":"Productos faltantes","effect":"light"}},[_c('i',{staticClass:"icon-package-variant f-20 text-general-red mx-2"})]):_vm._e()],1)])]}}])}),_c('el-table-column',{attrs:{"fixed":"","label":"Pedido","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(" "+_vm._s(row.id)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Vendedor","fixed":"","width":"120","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(" "+_vm._s(row.leechero)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"cliente","label":"Cliente","class-name":"text-center","min-width":"180"}}),_c('el-table-column',{attrs:{"prop":"cedis","label":"Cedis","class-name":"text-center","min-width":"180"}}),_c('el-table-column',{attrs:{"label":"Valor","min-width":"100","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(" "+_vm._s(_vm.separadorNumero(row.valor))+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"peso","min-width":"125","label":"Peso (Kg)","class-name":"text-center"}}),_c('el-table-column',{attrs:{"prop":"volumen","min-width":"140","label":"Volumen (m3)","class-name":"text-center"}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }